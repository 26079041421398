import React from 'react';
import { toast as rt } from 'react-toastify';

import ToastWithIcon from '../components/shared/ToastWithIcon';
import { Check } from '../core/icons';

const toast = {
  info: (message) => {
    rt.info(
      <ToastWithIcon
        icon="/static/icon-circle-info-blue.png"
        message={message}
      />
    );
  },
  error: (message) => {
    rt.error(
      <ToastWithIcon
        icon="/static/icon-exclamation-white.png"
        message={message}
      />
    );
  },
  errorHtml: (message) => {
    rt.error(
      <ToastWithIcon
        icon="/static/icon-exclamation-white.png"
        message={message}
        isHtml
      />
    );
  },
  success: (message) => {
    rt.success(<ToastWithIcon icon={<Check />} message={message} />);
  },
  dismiss: () => rt.dismiss(),
};

export default toast;
