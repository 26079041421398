import React, { FunctionComponent, ReactNode } from 'react';
import { Text } from 'mdlkit';

interface Props {
  icon: any;
  message: string | ReactNode;
  isHtml?: boolean;
}

const ToastWithIcon: FunctionComponent<Props> = ({
  icon,
  message,
  isHtml,
}: Props) => {
  const getMessage = (() => {
    if (typeof message === 'string') {
      if (isHtml) {
        return <div dangerouslySetInnerHTML={{ __html: message }} />;
      }
      return <Text>{message}</Text>;
    }
    return message;
  })();

  return (
    <div>
      <div className="Toastify__toast-icon">
        {typeof icon === 'string' ? <img src={icon} alt="icon" /> : icon}
      </div>
      {getMessage}
    </div>
  );
};

export default ToastWithIcon;
