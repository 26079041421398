import memoize from 'memoize-one';

const toIsoDate = (localDate: string) =>
  localDate.replace(/(\d\d)\/(\d\d)\/(\d{4})/, '$3-$1-$2');

const toLocalDate = (isoDate: string) => {
  const date = new Date(isoDate);

  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  });
};

const getMaxDay = (day: string, month?: string, year?: string) => {
  const parsedDay = day ? parseInt(day, 10) : 0;
  let allowOver28 = true;
  if (year && /[0-9]{4}/.test(year)) {
    // test for leap year
    allowOver28 = parseInt(year, 10) % 4 === 0;
  }

  let maxDay = 31;
  const monthsWith31 = [1, 3, 5, 7, 8, 10, 12];
  let parsedMonth: number | undefined;
  if (month) {
    parsedMonth = parseInt(month, 10);
    if (!monthsWith31.includes(parsedMonth)) maxDay = 30;
  }

  if (parsedMonth === 2) maxDay = 29;

  if (!allowOver28 && parsedMonth === 2) maxDay = 28;

  return { parsedDay, maxDay };
};

const alphabetical = (options) =>
  options.sort((a, b) => {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });

const sortByAlphabet = memoize(alphabetical);

const formatKey = (key) => key.replace(/ /g, '-');

const getTimestamp = (dateString): number => new Date(dateString).getTime();

const getAge = (day, month, year) => {
  const today = new Date();
  const birthDate = new Date(year, month - 1, day);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age -= 1;
  }

  return age;
};

export {
  toIsoDate,
  toLocalDate,
  getMaxDay,
  sortByAlphabet,
  formatKey,
  getTimestamp,
  getAge,
};
