import { isBefore, isValid } from 'date-fns';
import composeValidators from './composeValidators';
import { ErrorMessage } from './interfaces';
import { getMaxDay } from './helpers';

export { composeValidators };

export const alphaNumeric = (value: string): ErrorMessage =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? { id: 'validations.alphaNumeric' }
    : undefined;

export const minLength = (min: number) => (value: string): ErrorMessage =>
  value && value.length < min
    ? {
        id: 'validations.minLength',
        values: { min },
      }
    : undefined;

export const maxLength = (max: number) => (value: string): ErrorMessage =>
  value && value.length > max
    ? {
        id: 'validations.maxLength',
        values: { max },
      }
    : undefined;

export const exactLength = (target: number) => (value: string): ErrorMessage =>
  value && value.length === target
    ? undefined
    : {
        id: 'validations.exactLength',
        values: { target },
      };

export const exactLengthDigits = (target: number) => (
  value: number
): ErrorMessage =>
  value && value.toString().length === target
    ? undefined
    : {
        id: 'validations.exactLengthDigits',
        values: { target },
      };

export const required = (value: string): ErrorMessage =>
  value || typeof value === 'number'
    ? undefined
    : { id: 'validations.required' };

export const requiredArray = (value: string): ErrorMessage =>
  value && value.length > 0 ? undefined : { id: 'validations.requiredArray' };

export const requiredLetter = (value: string): ErrorMessage =>
  value && /[a-zA-Z]+/i.test(value)
    ? undefined
    : { id: 'validations.requiredLetter' };

export const requiredNumber = (value: string): ErrorMessage =>
  value && /[0-9]+/i.test(value)
    ? undefined
    : { id: 'validations.requiredNumber' };

export const requiredUppercase = (value: string): ErrorMessage =>
  value && /.*[A-Z]+/.test(value)
    ? undefined
    : { id: 'validations.requiredUppercase' };

export const requiredLowercase = (value: string): ErrorMessage =>
  value && /.*[a-z]+/.test(value)
    ? undefined
    : { id: 'validations.requiredLowercase' };

export const requiredSpecialCharacter = (value: string): ErrorMessage =>
  value && /(?=.*[^A-Za-z0-9])/.test(value)
    ? undefined
    : { id: 'validations.requiredSpecialCharacter' };

export const allLetters = (value: string): ErrorMessage =>
  value && !/[^a-z]+$/i.test(value)
    ? undefined
    : { id: 'validations.allLetters' };

export const allNumbers = (value: string): ErrorMessage =>
  value && /^[0-9]+$/.test(value)
    ? undefined
    : { id: 'validations.allNumbers' };

export const validDate = (value: string): ErrorMessage =>
  value && isValid(new Date(value)) && value.length === 10
    ? undefined
    : { id: 'validations.validDate' };

export const email = (value: string): ErrorMessage =>
  value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? undefined
    : { id: 'validations.email' };

export const isDevelopment = () => process.env.NODE_ENV === 'development';

export const phone = (value: string): ErrorMessage =>
  (value &&
    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(value) &&
    !/^([0-9])\1*$/.test(value)) ||
  isDevelopment()
    ? undefined
    : { id: 'validations.phone' };

export const before1900 = (value: string) => {
  const date = new Date(value);
  const year1900 = new Date('1900-01-01');

  return isBefore(date, year1900)
    ? { id: 'validations.before1900' }
    : undefined;
};

export const yearAfter1900 = (value: string): ErrorMessage =>
  parseInt(value, 10) < 1900 ? { id: 'validations.yearAfter1900' } : undefined;

export const yearInThePast = (value: string) =>
  parseInt(value, 10) > new Date().getFullYear()
    ? { id: 'validations.yearInThePast' }
    : undefined;

export const beforeNow = (value: string): ErrorMessage => {
  const date = new Date(value);
  const today = new Date();

  return isBefore(today, date) ? { id: 'validations.beforeNow' } : undefined;
};

export const birthdate = composeValidators(
  required,
  validDate,
  beforeNow,
  before1900
);

export const validName = composeValidators(required, allLetters, minLength(2));

export const zipCode = (value: string): ErrorMessage =>
  value && /^[0-9]{5}(?:-[0-9]{4})?$/i.test(value)
    ? undefined
    : { id: 'validations.zipCode' };

export const validDay = (
  day?: string,
  month?: string,
  year?: string
): ErrorMessage => {
  if (day) {
    const { parsedDay, maxDay } = getMaxDay(day, month, year);

    if (parsedDay > maxDay)
      return { id: 'validations.validDay', values: { maxDay } };
  }

  return undefined;
};

export const usernameOrEmail = (value: string) =>
  value?.includes('@') ? email(value) : undefined;
